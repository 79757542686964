body {
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  height: 175px;
  background-image: url("Components/Assets/igt_back2.jpg");
  margin-bottom: 75px;
  position: fixed;
  z-index: 10;
  margin-left: -1%;
  width: 1877px;
}

.main-content {
  top: 200px;
  position: relative;
}

.center_headings h1, h2, h3, h4, h5, a {
  text-align: center;
}

.card-sizing {
  height: 100%;
  padding: 5px;
  margin: 5px;
}

.sticky-header {
  position: sticky;
  top: 174px;
  white-space: nowrap;
  background-color: white;
  z-index: 4;
}
